import React from 'react';
import qs from 'query-string';
import { navigate } from 'gatsby';
import { Box, Spinner, Text } from '@frameio/vapor';
import { handleAuth } from '@frameio/developer-components';
import { isBrowser } from '../utils';

const AuthCallback = () => {
  if (!isBrowser()) {
    return <div />;
  }

  const { redirect_path: redirectPath } = qs.parse(window.location.search);

  handleAuth(() => navigate(redirectPath));

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Spinner size="small" />
      <Text style={{ margin: '10px' }} color="brandPrimary">
        Loading auth...
      </Text>
    </Box>
  );
};
export default AuthCallback;
